import './App.css';
import audio from './assets/audio.mp3';
import React, { useState } from 'react';


function App() {

  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.useRef(new Audio(audio));

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Verificar se a senha está correta
    if (password === 'larinha') {
      setIsAuthenticated(true);
      audioRef.current.play();
      setIsPlaying(true);

    } else {
      alert('Senha incorreta. Tente novamente.');
    }
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  if(isAuthenticated){
    return (
      <div className="App">
        <header className="App-header">
          <button onClick={togglePlay} className="App-Button">
            {isPlaying ? 'Pausar Música' : 'Tocar Música'}
          </button>        
        </header>
        <body className='Body'>
          <container className = "Letter-Container">
            <text className = "Letter-Title">Oi, meu biscoitinho de polvilho, </text>
            <br></br>
            <br></br>
            <text className = "Letter-Text"> 
            desde uns anos pra ca eu sempre fui bem conformado com minha vida, principalmente a amorosa. Achava que as chances de encontrar alguem que combinasse comigo fossem baixíssimas, e nao iria acontecer, e estava tudo bem. Eu estava em um momento em que eu apenas precisava de alguem, nao precisava ser a pessoa perfeita pra mim.
            </text>
            <br></br>
            <text className = "Letter-Text"> 
            Quando te vi no Instagram, meu primeiro pensamento foi: ela é bonita demais pra mim, acho que to viajando. Mas mesmo assim eu tentei, e você deu corda. E quanto mais a gente conversava mais eu me perguntava se aquilo era verdade, uma pessoa tao linda, tao inteligente e legal se abrindo pra mim, confiando em mim, e se sentindo bem conversando comigo.  Isso me deixava muito feliz, uma felicidade que eu não sentia a muito tempo.
            </text>
            <br></br>
            <text className = "Letter-Text"> 
            Hoje você continua sendo essa pessoa, voce nao mudou nada. Eu nao estava procurando a pessoa perfeita pra mim, mas, por coincidência, acabei encontrando. O que eu sinto por você nao é so físico, voce é meu porto seguro, é a pessoa que posso contar sempre que preciso, é pra onde eu vou quanto estou confuso e perdido, você é a primeira coisa que eu penso quando acordo e a ultima quando vou dormir. Estar com você me faz ver sentido na vida, um objetivo claro, uma prova de que estamos aqui por uma razão. 
            </text>
            <br></br>
            <text className = "Letter-Text"> 
            Você é uma obra de arte. Tao linda e delicada,  com uma mente extremamente complexa. Sua inteligência me atraí,  quando eu converso com voce eu percebo que eu estou atrás,  e isso me deixa muito feliz, pois eu lembro que uma das pessoas mais inteligentes com que eu ja conversei namora comigo.  
            </text>
            <br></br>
            <text className = "Letter-Text"> 
            Eu te amo muito, e jamais te trocaria por ninguém. Pois eu sei que nada vai valer mais a pena do que viver o resto na minha vida com você, sem culpas e arrependimentos. Sei que você tem medo de ter mudancas fisicas durante esse tratamento, mas, sinceramente,  isso nao importa pra mim. Você sempre sera minha nenem e eu sempre terei um crush no seu buxinho. Sua saude mental é o que mais importa pra mim, eu estou namorando você, sua personalidade e seu carisma me atraíram, e nao apenas o seu corpo, e isso vai continuar assim.
            </text>
            <br></br>
            <br></br>
            <text className = "Letter-Text"> 
            Voce é a coisa que eu mais amo nesse mundo, e é a coisa mais importante pra mim. Por isso, eu peço que você se dedique no seu tratamento, pra que a gente olhe pra trás e veja essa fase como superada, pra podermos partir pra próxima. Eu sempre estarei com você, e nunca irei te abandonar, eu sou seu e você é  minha.
            </text>
          </container>
          <container className = "Letter-Container">
            <h1 className = "Letter-Text">Eu te amo </h1>
          </container>
        </body>
      </div>
    );
  }
  else{
    return (
      <div className="Password">
          <form onSubmit={handleFormSubmit}>
              <input
                type="password"
                value={password}
                onChange={handlePasswordChange}
                className='Login-Input'
                placeholder='Digite a senha'
              />
            <button type="submit" className='Login-Button'>Entrar</button>
          </form>

      </div>
    );
  }
}

export default App;
